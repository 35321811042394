

























import { defineComponent } from '@vue/composition-api'
import { useInterval } from '@/hooks/useInterval'

export default defineComponent({
  name: 'IntervalInput',
  props: {
    editable: { type: Boolean, required: true }
  },
  setup() {
    const { interval, isMinuteSession, minus, plus } = useInterval()

    return {
      interval,
      isMinuteSession,
      minus,
      plus
    }
  }
})
