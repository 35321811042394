/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { setNotificationInstance, notify } from './utils/notification'
import i18n from '@/i18n'
import { emit } from 'retrobus'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(sw) {
      sw.getNotifications().then((notifs) => {
        notifs.forEach((notif) => notif.close())
      })
      console.log('App is being served from cache by a service worker')
      setNotificationInstance((title, options?) =>
        sw.showNotification(title, options)
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(sw) {
      console.log('New content is available; please refresh.')
      setNotificationInstance((title, options?) =>
        sw.showNotification(title, options)
      )
      notify(i18n.t('notification.update.title').toString(), {
        body: i18n.t('notification.update.body').toString(),
        tag: 'new-version'
      })
      emit('new-version')
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
