interface WakeLock {
  request(type: string): Promise<WakeLockSentinel>
}

type WakeLockType = 'screen' | null

interface WakeLockSentinel {
  release(): Promise<void>
  readonly WakeLocktype: WakeLockType
}

interface NavigatorExtended extends Navigator {
  readonly wakeLock: WakeLock
}

export const useWakeLock = () => {
  let wakeLock: WakeLockSentinel | null = null

  const askWakeLockPermission = async () => {
    if (!('wakeLock' in navigator)) {
      return
    }

    try {
      wakeLock = await (navigator as NavigatorExtended).wakeLock.request(
        'screen'
      )
    } catch (err) {
      return
    }
  }

  const releaseWakeLock = () => {
    wakeLock?.release()
  }

  return {
    askWakeLockPermission,
    releaseWakeLock
  }
}
