



































































import { defineComponent, onMounted } from '@vue/composition-api'
import { useTimer } from '@/hooks/useTimer'
import DevSession from '@/components/DevSession.vue'
import ZoneMusic from '@/components/ZoneMusic.vue'
import IntervalInput from '@/components/IntervalInput.vue'

export default defineComponent({
  name: 'Home',
  components: {
    DevSession,
    ZoneMusic,
    IntervalInput
  },
  props: {
    play: { type: Boolean, default: false }
  },
  setup(props) {
    const {
      interval,
      start,
      pause,
      clear,
      timeState,
      time,
      session,
      isDev1Turn
    } = useTimer()

    const toggle = () => {
      timeState.value === 'stopped' ? start() : pause()
    }

    onMounted(() => {
      if (props.play) {
        start()
      }
    })

    return {
      interval,
      toggle,
      start,
      pause,
      clear,
      timeState,
      time,
      session,
      isDev1Turn
    }
  }
})
