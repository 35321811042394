import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api'
import { StopwatchState } from '@/types/StopwatchState'
import { notify } from '@/utils/notification'
import { useInterval } from './useInterval'
import { useWakeLock } from './useWakeLock'
import i18n from '@/i18n'
import { useGetters } from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { State } from '@/store'

const toggleSound = new Audio('/sound/toggle.mp3')

const format = (num: number) => {
  return num.toString().padStart(2, '0')
}

const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const secs = seconds % 60

  return `${format(minutes)}:${format(secs)}`
}

export const useTimer = () => {
  const { askWakeLockPermission, releaseWakeLock } = useWakeLock()
  const { dev1, dev2 } = useGetters<GetterTree<State, State>>(['dev1', 'dev2'])

  const timeState = ref<StopwatchState>('stopped')
  const seconds = ref(0)
  const { interval } = useInterval()
  const sessionSeconds = ref(interval.value * 60)
  const isDev1Turn = ref(true)

  const time = computed(() => formatSeconds(seconds.value))
  const session = computed(() => formatSeconds(sessionSeconds.value))
  const intervalSeconds = computed(() => interval.value * 60)

  let stopwatchId: NodeJS.Timeout | null = null
  let stopWatchSessionId: NodeJS.Timeout | null = null

  watch(interval, () => {
    sessionSeconds.value = interval.value * 60
  })

  const start = () => {
    askWakeLockPermission()
    timeState.value = 'started'

    stopwatchId = setInterval(() => {
      seconds.value++
      const isTimeToChange = seconds.value % intervalSeconds.value === 0
      if (isTimeToChange) {
        sessionSeconds.value = intervalSeconds.value
        isDev1Turn.value = !isDev1Turn.value

        const dev = isDev1Turn.value ? dev1.value : dev2.value

        toggleSound.play()

        notify(i18n.t('notification.change.title').toString(), {
          body: i18n.t('notification.change.body', { dev }).toString()
        })
      }
    }, 1000)

    stopWatchSessionId = setInterval(() => {
      sessionSeconds.value--
    }, 1000)
  }

  const pause = () => {
    releaseWakeLock()
    timeState.value = 'stopped'
    if (stopwatchId) {
      clearInterval(stopwatchId)
      stopwatchId = null
    }
    if (stopWatchSessionId) {
      clearInterval(stopWatchSessionId)
      stopWatchSessionId = null
    }
  }

  const clear = () => {
    pause()
    seconds.value = 0
    sessionSeconds.value = intervalSeconds.value
    isDev1Turn.value = true
  }

  const handleSpacebar = (event: KeyboardEvent) => {
    if (event.target !== document.body) {
      return
    }
    if (event.charCode === 32) {
      timeState.value === 'started' ? pause() : start()
    }
  }

  onMounted(() => {
    window.addEventListener('keypress', handleSpacebar)
  })

  onUnmounted(() => {
    window.removeEventListener('keypress', handleSpacebar)
  })

  return {
    interval,
    start,
    pause,
    clear,
    timeState,
    time,
    session,
    isDev1Turn
  }
}
