import { useGetters, useActions } from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { State, RootActions } from '@/store'

export const useMusic = () => {
  const isIOS =
    navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  const { hasMusic, hasSpotify } = useGetters<GetterTree<State, State>>([
    'hasMusic',
    'hasSpotify'
  ])
  const { setHasMusic, setHasSpotify } = useActions<RootActions>([
    'setHasMusic',
    'setHasSpotify'
  ])

  return {
    isMusicAvailable: !isIOS,
    hasMusic,
    setHasMusic,
    hasSpotify,
    setHasSpotify
  }
}
