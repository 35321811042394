import Vue from 'vue'
import Vuex, { ActionContext, ActionTree } from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export interface State {
  interval: number
  hasMusic: boolean
  hasSpotify: boolean
  song: string | null
  dev1: string | null
  dev2: string | null
  accessToken: string | null
  tokenExpire: Date | null
}

export interface RootActions extends ActionTree<State, State> {
  setInterval: (ctx: ActionContext<State, State>, payload: number) => void
  setHasMusic: (ctx: ActionContext<State, State>, payload: boolean) => void
  setHasSpotify: (ctx: ActionContext<State, State>, payload: boolean) => void
  setDev1: (ctx: ActionContext<State, State>, payload: string) => void
  setDev2: (ctx: ActionContext<State, State>, payload: string) => void
  setAccessToken: (ctx: ActionContext<State, State>, payload: string) => void
  setTokenExpire: (ctx: ActionContext<State, State>, payload: Date) => void
}

const SET_INTERVAL = 'SET_INTERVAL'
const WITH_MUSIC = 'WITH_MUSIC'
const WITH_SPOTIFY = 'WITH_SPOTIFY'
const SET_SONG = 'SET_SONG'
const SET_DEV_1 = 'SET_DEV_1'
const SET_DEV_2 = 'SET_DEV_2'
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
const SET_TOKEN_EXPIRE = 'SET_TOKEN_EXPIRE'

const vuexLocal = new VuexPersistence<State>({
  key: 'binome',
  storage: window.localStorage,
  filter: (mutation) => mutation.type !== SET_SONG
})

const store = new Vuex.Store<State>({
  state: {
    interval: 5,
    hasMusic: false,
    hasSpotify: false,
    song: null,
    dev1: null,
    dev2: null,
    accessToken: null,
    tokenExpire: null
  },
  getters: {
    interval: ({ interval }) => interval,
    hasMusic: ({ hasMusic }) => hasMusic,
    hasSpotify: ({ hasSpotify }) => hasSpotify,
    song: ({ song }) => song,
    dev1: ({ dev1 }) => dev1,
    dev2: ({ dev2 }) => dev2,
    accessToken: ({ accessToken }) => accessToken,
    hasValidAccessToken: ({ accessToken, tokenExpire }) => {
      if (!accessToken || !tokenExpire) {
        return false
      }
      return new Date() < tokenExpire
    }
  },
  mutations: {
    [SET_INTERVAL](state, interval: number) {
      state.interval = interval
    },
    [WITH_MUSIC](state, hasMusic: boolean) {
      state.hasMusic = hasMusic
    },
    [WITH_SPOTIFY](state, hasSpotify: boolean) {
      state.hasSpotify = hasSpotify
    },
    [SET_SONG](state, song: string | null) {
      state.song = song
    },
    [SET_DEV_1](state, dev1: string) {
      state.dev1 = dev1
    },
    [SET_DEV_2](state, dev2: string) {
      state.dev2 = dev2
    },
    [SET_ACCESS_TOKEN](state, accessToken: string) {
      state.accessToken = accessToken
    },
    [SET_TOKEN_EXPIRE](state, tokenExpire: Date) {
      state.tokenExpire = tokenExpire
    }
  },
  actions: {
    setInterval({ commit }, interval: number) {
      if (interval > 0) {
        commit(SET_INTERVAL, interval)
      }
    },
    setHasMusic({ commit }, hasMusic: boolean) {
      commit(WITH_MUSIC, hasMusic)
    },
    setHasSpotify({ commit }, hasSpotify: boolean) {
      commit(WITH_SPOTIFY, hasSpotify)
    },
    setDev1({ commit }, dev1: string) {
      commit(SET_DEV_1, dev1)
    },
    setSong({ commit }, song: string) {
      commit(SET_SONG, song)
    },
    setDev2({ commit }, dev2: string) {
      commit(SET_DEV_2, dev2)
    },
    setAccessToken({ commit }, accessToken: string) {
      commit(SET_ACCESS_TOKEN, accessToken)
    },
    setTokenExpire({ commit }, tokenExpire: Date) {
      commit(SET_TOKEN_EXPIRE, tokenExpire)
    }
  },
  plugins: [vuexLocal.plugin]
})

const cleanStore = () => {
  store.dispatch('setSong', null)
}

cleanStore()

export default store
