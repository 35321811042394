










import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { addEventBusListener } from 'retrobus'

export default defineComponent({
  name: 'SWNewVersion',
  setup() {
    const newVersion = ref(false)

    onMounted(() => {
      addEventBusListener('new-version', () => (newVersion.value = true), {
        retro: true,
        once: true
      })
    })

    return {
      newVersion,
      reload: () => location.reload(true)
    }
  }
})
