type ShowNotification = (
  title: string,
  options?: NotificationOptions
) => Promise<void>

let showNotification: ShowNotification | null = null

export const setNotificationInstance = (sn: ShowNotification) => {
  showNotification = sn
}

export const notify: ShowNotification = async (title, options?) => {
  const showCustomNotification = async () => {
    if (!showNotification) {
      return
    }
    await showNotification(title, {
      icon: '/img/icons/apple-touch-icon.png',
      badge: '/img/badge.png',
      tag: 'binome',
      renotify: true,
      silent: true,
      ...options
    })
  }

  try {
    if (!('Notification' in window)) {
      return
    }

    if (Notification.permission === 'granted') {
      showCustomNotification()
      return
    }

    if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission()

      if (permission === 'granted') {
        showCustomNotification()
      }
    }
  } catch (error) {
    return
  }
}
