import { useGetters, useActions } from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { State, RootActions } from '@/store'
import { computed } from '@vue/composition-api'

const MIN_INTERVAL = 0.5
const MAX_INTERVAL = 60

export const useInterval = () => {
  const { interval } = useGetters<GetterTree<State, State>>(['interval'])
  const { setInterval } = useActions<RootActions>(['setInterval'])

  const minus = () => {
    if (interval.value > MIN_INTERVAL) {
      setInterval(Math.max(interval.value - 1, MIN_INTERVAL))
    }
  }

  const plus = () => {
    setInterval(Math.min(Math.floor(interval.value + 1), MAX_INTERVAL))
  }

  const isMinuteSession = computed(() => interval.value >= 1)

  return {
    interval,
    isMinuteSession,
    minInterval: MIN_INTERVAL,
    minus,
    plus
  }
}
