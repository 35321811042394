













import { defineComponent, ref, computed } from '@vue/composition-api'
import { useGetters, useActions } from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { State, RootActions } from '@/store'

export default defineComponent({
  name: 'DevSession',
  props: {
    position: { type: String, required: true },
    isTurn: { type: Boolean, required: true },
    session: { type: String, required: true }
  },
  setup(props) {
    const { dev1, dev2 } = useGetters<GetterTree<State, State>>([
      'dev1',
      'dev2'
    ])
    const { setDev1, setDev2 } = useActions<RootActions>(['setDev1', 'setDev2'])

    const isDev1 = props.position === '1'

    const placeholder = ref(`dev ${props.position}`)

    const dev = computed(() => (isDev1 ? dev1.value : dev2.value))

    const setDev = (event: InputEvent) => {
      const name = (event.target as HTMLInputElement).value
      isDev1 ? setDev1(name) : setDev2(name)
    }

    const check = () => {
      if (!dev.value) {
        isDev1 ? setDev1(placeholder.value) : setDev2(placeholder.value)
      }
    }

    return {
      placeholder,
      dev,
      setDev,
      check
    }
  }
})
