

























































import { GetterTree } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { State } from '@/store'
import { useMusic } from '@/hooks/useMusic'
import { useGetters } from 'vuex-composition-helpers'

export default defineComponent({
  name: 'ZoneMusic',
  components: {
    ChilledMusic: () => import('@/components/ChilledMusic.vue'),
    SpotifyMusic: () => import('@/components/SpotifyMusic.vue')
  },
  props: {
    play: {
      type: Boolean,
      required: true
    }
  },
  setup(_, { emit }) {
    const { song } = useGetters<GetterTree<State, State>>(['song'])
    const {
      isMusicAvailable,
      hasSpotify,
      setHasSpotify,
      hasMusic,
      setHasMusic
    } = useMusic()

    const onPlay = () => {
      emit('play')
    }

    const onPause = () => {
      emit('pause')
    }

    return {
      isMusicAvailable,
      hasSpotify,
      setHasSpotify,
      hasMusic,
      setHasMusic,
      onPlay,
      onPause,
      song
    }
  }
})
