








import { defineComponent } from '@vue/composition-api'
import SWNewVersion from '@/components/SWNewVersion.vue'

export default defineComponent({
  name: 'App',
  components: {
    SWNewVersion
  }
})
